import * as React from "react"
import { Helmet } from "react-helmet"

import IDALayout from "../../components/layouts/layout-ida"
import Seo from "../../components/seo"

import EopCalloutCards from "../../components/shared/generic/eop-callout-cards"
import ImgMediaCard from "../../components/shared/generic/media-card"

import CalloutIcon1 from "../../images/icons/icon-callout-ida-efficacy.png"
import akbcares from "../../images/AkebiaCares-logo.png"
import iconmeals from "../../images/ida/dosing/icon-meals-2x.png"
import icontablets from "../../images/ida/dosing/icon-tablets-2x.png"
import iconcalendar from "../../images/ida/dosing/icon-calendar-2x.png"
import iconmaxdose from "../../images/ida/dosing/icon-max-dose-2x.png"

const DosingPage = ({ location }) => (
    <IDALayout
        location={location}
        locationHostName={location.hostname}
        jobCode="PP-AUR-US-1408 (v4.0)"
        jobCodeDate="09/24"
        references={[
            <>
                AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA: Akebia
                Therapeutics, Inc.; 2021.
            </>,
            <>
                Fishbane S, Block GA, Loram L, et al. Effects of ferric citrate
                in patients with nondialysis-dependent CKD and iron deficiency
                anemia. <em>J Am Soc Nephrol</em>. 2017; 28(6):1851-1858.
            </>,
            <>Data on File 16, Akebia Therapeutics, Inc.</>,
            <>Data on File 14, Akebia Therapeutics, Inc.</>,
        ]}
    >
        <Helmet>
            <html lang="en" />
            <link
                rel="canonical"
                href="https://www.auryxiahcp.com/iron-deficiency-anemia/dosing"
            />
            <body className="ida" id="ida-hcp-dosing" />
        </Helmet>

        <Seo
            title="Dosing"
            description="See the dosing and titration information for AURYXIA® (ferric citrate) tablets."
        />
        <section id="hero" className="center">
            <div className="flex-row">
                <div>
                    <h1>
                        AURYXIA provides the convenience of{" "}
                        <span className="nowrap">at-home</span> dosing
                        <sup>1</sup>
                    </h1>
                    <p>
                        <strong>
                            Based on the oral administration of AURYXIA:
                        </strong>
                    </p>
                    <ul>
                        <li>No needles</li>
                        <li>No trips to infusion centers</li>
                        <li>
                            Patients don&#8217;t have to leave the comfort of
                            their own home
                        </li>
                    </ul>
                </div>
                <hr />
            </div>
        </section>

        <section id="guidelines" className="col-10 center">
            <div className="flex-row">
                <div>
                    <h2 className="ida">
                        Dosing guidelines<sup>1</sup>
                    </h2>
                    <div className="media-cards-wrap ida">
                        <ImgMediaCard
                            siteAudience="ida"
                            delay="100"
                            imgalt="icon"
                            imgsrc={iconmeals}
                            content={
                                <>
                                    Swallow whole
                                    <br />
                                    with meals
                                </>
                            }
                        />
                        <ImgMediaCard
                            siteAudience="ida"
                            delay="500"
                            imgalt="icon"
                            imgsrc={icontablets}
                            content={
                                <>
                                    Starting dose{" "}
                                    <strong>
                                        1&nbsp;tablet
                                        <br />3 times/day
                                    </strong>
                                </>
                            }
                        />
                        <ImgMediaCard
                            siteAudience="ida"
                            delay="700"
                            imgalt="icon"
                            imgsrc={iconcalendar}
                            content={
                                <>
                                    Monitor and adjust the dose{" "}
                                    <strong>as&nbsp;needed*</strong>
                                </>
                            }
                        />
                        <ImgMediaCard
                            siteAudience="ida"
                            delay="900"
                            imgalt="icon"
                            imgsrc={iconmaxdose}
                            content={
                                <>
                                    Maximum dose
                                    <br />
                                    <strong>12 tablets daily</strong>
                                </>
                            }
                        />
                    </div>
                </div>
            </div>
        </section>

        <section id="coadministration" className="flood-ida col-8 center">
            <div className="flex-row">
                <div>
                    <p
                        className="subhead"
                        data-sal="zoom-in"
                        data-sal-delay="200"
                        data-sal-duration="600"
                        data-sal-easing="ease-out-expo"
                    >
                        <strong>AURYXIA delivers:</strong> 210 mg of ferric iron
                        per tablet<sup>1&#x2020;</sup>
                    </p>

                    <p
                        className="subhead"
                        data-sal="zoom-in"
                        data-sal-delay="800"
                        data-sal-duration="600"
                        data-sal-easing="ease-out-expo"
                    >
                        &gt;1500 mg of ferric iron in 3 days<sup>1&#x2020;</sup>
                    </p>

                    <p>
                        {" "}
                        <span className="dosing_span_margin">*</span>Titrate the
                        dose of AURYXIA as needed to achieve and maintain
                        hemoglobin levels.
                        <br />
                        <sup className="dosing_sup_margin">&#x2020;</sup>
                        Represents ferric iron delivered and not ferric iron
                        absorbed. Calculation is based on 210 mg of ferric iron
                        per tablet and the starting dose of 1 tablet 3 times/day
                        with meals. In the clinical trial, patients required an
                        average of 5 tablets per day to increase hemoglobin
                        levels.{" "}
                    </p>
                </div>
            </div>
        </section>

        <section id="interactions" className="col-8 center">
            <div className="flex-row">
                <div>
                    <h3 className="ida">
                        Oral drugs that cannot be taken at the same time as
                        AURYXIA<sup>1</sup>
                    </h3>
                    <p>
                        Be aware of the drug-to-drug interactions of AURYXIA
                        before prescribing to patients
                    </p>
                    <ul>
                        <li>
                            Doxycycline&#x2014;Take at least{" "}
                            <strong>1 hour before</strong> AURYXIA
                        </li>
                        <li>
                            Ciprofloxacin&#x2014;Take at least{" "}
                            <strong>2 hours before or after</strong> AURYXIA
                        </li>
                        <li>
                            For oral medications where a reduction in
                            bioavailability would have a clinically significant
                            effect, consider separation of timing of
                            administration with AURYXIA
                        </li>
                    </ul>
                </div>
            </div>
        </section>

        <EopCalloutCards
            siteAudience="ida"
            callOneClass={"highlight"}
            callOneLink={"/iron-deficiency-anemia/efficacy/"}
            callOneContent={
                <>
                    <img src={CalloutIcon1} alt="" width="98" height="98" />
                    <h3>See what AURYXIA can do for your patients</h3>
                    <p>
                        AURYXIA increased Hgb and iron parameters (TSAT and
                        ferritin) without IV iron or ESA use in a 16-week
                        pivotal trial.<sup>1,2-4</sup>
                    </p>
                    <button className="cta ida">
                        <span>EXAMINE EFFICACY</span>
                    </button>
                    <p className="footnote center">
                        IV iron use and ESA use were not allowed per trial
                        design and exclusion criteria.<sup>1</sup>
                    </p>
                </>
            }
            callTwoClass={"akebia-cares"}
            callTwoLink={"https://www.akebiacareshcp.com/"}
            callTwoContent={
                <>
                    <img src={akbcares} alt="AkebiaCares" height="81" />
                    <h3>
                        Your partner in helping patients access the medication
                        they need
                    </h3>
                    <p>
                        AkebiaCares supports your patients with information and
                        resources that can help people start and stay on
                        AURYXIA.
                    </p>
                    <button className="cta akb">
                        <span>FIND SUPPORT OPTIONS</span>
                    </button>
                </>
            }
        />
    </IDALayout>
)

export default DosingPage
